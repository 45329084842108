<template>
    <div class="container card text-white bg-dark mb-3">
    
        <div class="card-header">
            {{$t("message.Change_Password")}}
        </div>
        <form class="card-body">
            <div class="form-group">
                <label for="psw_old">{{$t("message.Old_password")}}</label>
                <input type="password" class="form-control" id="psw_old" :placeholder="$t('message.Old_password')" v-model="old_psw">
            </div>
            <div class="form-group">
                <label for="psw_new">{{$t("message.Password")}}</label>
                <input type="password" class="form-control" id="psw_new" :placeholder="$t('message.Password')" v-model="new_psw">
            </div>
            <div class="form-group">
                <label for="psw_new_1">{{$t("message.Confirm_password")}}</label>
                <input type="password" class="form-control" id="psw_new_1" :placeholder="$t('message.Confirm_password')" v-model="confirm_psw">
            </div>
            <button  class="btn btn-primary" @click="changepwd()">{{ $t("message.Save") }}</button>
        </form>
    
    
    </div>
</template>

<script>
 import {
    mapActions,
    mapGetters
  } from "vuex";
export default {
    name: "ModifyPswSection",
    data() {
        return {
            old_psw: '',
            new_psw: '',
            confirm_psw: ''
        }
    },
     computed: {
      ...mapGetters(["loginname"])
    },
    methods: mapActions({

        changepwd(dispatch) {

            if (this.new_psw != this.confirm_psw) {
                // $('#psw_check').hide()
                // this.$refs.psw_check.hide
                //   this.$refs.psw_check.alert('close');
                  this.$bvToast.toast(this.$t("message.Password_must_the_same"), {
                        title: ``,
                        toaster: 'b-toaster-bottom-center',
                        solid: true,
                        variant: 'danger',
                        appendToast: false
                    })
                return
            }

            let password1 = this.old_psw
            let password2 = this.new_psw
                let usrnm=this.loginname
                dispatch("changepwd", {
                    password1,
                    password2,
                    usrnm
                }).then(data => {
                    if (data.result) {
                        this.$bvToast.toast(this.$t("message.Modify_password_success"), {
                            title: ``,
                            toaster: 'b-toaster-bottom-center',
                            solid: true,
                            variant: 'success',
                            appendToast: false
                        })
                        this.old_psw=''
                        this.new_psw=''
                        this.confirm_psw=''

                    } else {
                        this.$bvToast.toast(this.$t("message.Modify_password_failed"), {
                            title: ``,
                            toaster: 'b-toaster-bottom-center',
                            solid: true,
                            variant: 'danger',
                            appendToast: false
                        })
                    }
                });
        }
    }),
}
</script>