<template>
    <div class="card text-center">
        <div class="card-header">
            <ul class="nav nav-tabs card-header-tabs">
                <li class="nav-item" @click="getUnPayOrder()">
                    <a class="nav-link" :class="{'active':payStatus==0}" href="#">{{$t("message.Unpaid_Order")}}</a>
                    <small>{{$t('message.Unpay_cancle')}}</small>
                </li>
                <li class="nav-item" @click="getPayOrder()">
                    <a class="nav-link" :class="{'active':payStatus==1}" href="#">{{$t("message.Paid_Order")}}</a>
                </li>
            </ul>
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <table class="table">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col">{{$t("message.Order_Number")}}</th>
                            <th scope="col">{{$t("message.Order_Date")}}</th>
                            <th scope="col">{{$t("message.UserName")}}</th>
                            <th scope="col">{{$t("message.Payment_Way")}}</th>
                            <th scope="col">{{$t("message.Total")}}</th>
                            <th scope="col">{{$t("message.Order_Status")}}</th>
                        </tr>
                    </thead>
                    <tbody v-for="od in orderls" :key="od">

                        <tr @mouseover="mouseov(od)" @mouseout="mousout()">
                            <td>{{od.payno}}</td>
                            <td>{{od.createat | time}}</td>
                            <td>{{od.contact}}</td>
                            <td>---</td>
                            <td>{{od.currency==0?'':symble_money}}{{od.currency==0?od.amount:((lan=='jp' || lan=='kr')?(od.amount*od.currency).toFixed(0):(od.amount*od.currency).toFixed(2))}}</td>
                            <td v-if="payStatus==1" class="text-success">{{$t("message.Success")}}</td>
                            <td v-if="payStatus==0">
                                <button class="btn btn-outline-danger"
                                    @click="orderConfirm(od)">{{$t("message.To_Checkout")}}</button>
                            </td>
                        </tr>

                        <tr :class="{'d-none':(  ods!=od)}">
                            <td colspan="6">
                                <div class="table-responsive">
                                    <table class="table table-dark">

                                        <thead>
                                            <tr>
                                                <th scope="col">{{$t("message.Product_Name")}}</th>
                                                <th scope="col">{{$t("message.Count")}}</th>
                                                <th scope="col">{{$t("message.Price")}}</th>
                                                <th scope="col">{{$t("message.Subtotal")}}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="odd in od.orders" :key="odd">
                                                <th scope="row">{{odd.skuname}}</th>
                                                <td>{{odd.count}}</td>
                                                <td>{{od.currency==0?'':symble_money}}{{ od.currency==0?odd.price:((lan=='jp' || lan=='kr')?(odd.price*od.currency).toFixed(0):(odd.price*od.currency).toFixed(2))}}</td>
                                                <td>{{od.currency==0?'':symble_money}}{{ od.currency==0?parseFloat(odd.price*odd.count ).toFixed(2):((lan=='jp' || lan=='kr')?parseFloat(odd.price*odd.count*od.currency ).toFixed(0):parseFloat(odd.price*odd.count*od.currency ).toFixed(2))}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        mapActions,
        mapGetters
    } from "vuex";

    export default {

        name: "OrderLsSection",
        data() {
            return {
                ods: '',
                orderls: [],
                payStatus: 0
            }
        },
        computed: {
            ...mapGetters(["symble_money", "access_token", "lan"]),
        },
        watch: {
            lan(oldlan, newlan) {
                this.getUnPayOrder();
            }
        },
        mounted() {
            this.getUnPayOrder();
        },
        methods: {
            ...mapActions({

                getUnPayOrder(dispatch) {
                    this.payStatus = 0
                    dispatch("getUnPayOrder", {}).then(data => {
                        if (data.result) {
                            console.log("==============" + data.items)
                            this.orderls = data.items
                        } else {
                            if (data.status == 'logout') {
                                this.showToast(this.$t("message.You_logout"))
                                this.logout()
                            }
                        }
                    });
                },
                getPayOrder(dispatch) {
                    this.payStatus = 1
                    dispatch("getPayOrder", {}).then(data => {
                        if (data.result) {

                            this.orderls = data.items
                        } else {
                            if (data.status == 'logout') {
                                this.showToast(this.$t("message.You_logout"))
                                this.logout()
                            }
                        }
                    });
                },
                orderConfirm(dispatch, od) {
                    dispatch("")
                    console.log("==========lanlan====" + this.lan)
                    if (this.lan != 'cn') {
                        //   var access_token = JSON.parse(this.access_token);
                        var pay_signatureen = this.getFromBase64(this.access_token);
                        // window.location =
                        //     "http://bluedio.com/paypal/checkout?orderno=" +
                        //     od.payno +
                        //     "&pay_signature=" +
                        //     pay_signature;
                        var pay_type = 1;
                        if (this.access_token == ""){
                            pay_type = 0;
                        }
                        window.location =
                            "/paypal/checkout/vue?orderno=" +
                            od.payno +
                            "&pay_signature=" +
                            pay_signatureen +
                            "&pay_type=" +
                            pay_type;

                    } else {

                        //   var access_token = JSON.parse(this.access_token);
                        var pay_signature = this.getFromBase64(this.access_token);

                        window.location =
                            "/alipay/payment?orderno=" +
                            od.payno +
                            "&pay_signature=" +
                            pay_signature;

                        // window.location =
                        //     "/alipay/payment?orderno=" +
                        //     od.payno +
                        //     "&pay_signature=" +
                        //     pay_signature;


                    }
                },

                getFromBase64(dispatch, str) {
                    var Base64 = window.Base64 || {
                        /* private property*/
                        _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",

                        /* public method for encoding */
                        encode: function (input) {
                            var output = "";
                            var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
                            var i = 0;

                            input = Base64._utf8_encode(input);

                            while (i < input.length) {
                                chr1 = input.charCodeAt(i++);
                                chr2 = input.charCodeAt(i++);
                                chr3 = input.charCodeAt(i++);

                                enc1 = chr1 >> 2;
                                enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
                                enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
                                enc4 = chr3 & 63;

                                if (isNaN(chr2)) {
                                    enc3 = enc4 = 64;
                                } else if (isNaN(chr3)) {
                                    enc4 = 64;
                                }

                                output =
                                    output +
                                    Base64._keyStr.charAt(enc1) +
                                    Base64._keyStr.charAt(enc2) +
                                    Base64._keyStr.charAt(enc3) +
                                    Base64._keyStr.charAt(enc4);
                            }

                            return output;
                        },

                        /* public method for decoding */
                        decode: function (input) {
                            var output = "";
                            var chr1, chr2, chr3;
                            var enc1, enc2, enc3, enc4;
                            var i = 0;

                            input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");

                            while (i < input.length) {
                                enc1 = Base64._keyStr.indexOf(input.charAt(i++));
                                enc2 = Base64._keyStr.indexOf(input.charAt(i++));
                                enc3 = Base64._keyStr.indexOf(input.charAt(i++));
                                enc4 = Base64._keyStr.indexOf(input.charAt(i++));

                                chr1 = (enc1 << 2) | (enc2 >> 4);
                                chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
                                chr3 = ((enc3 & 3) << 6) | enc4;

                                output = output + String.fromCharCode(chr1);

                                if (enc3 != 64) {
                                    output = output + String.fromCharCode(chr2);
                                }
                                if (enc4 != 64) {
                                    output = output + String.fromCharCode(chr3);
                                }
                            }

                            output = Base64._utf8_decode(output);

                            return output;
                        },

                        /* private method for UTF-8 encoding */
                        _utf8_encode: function (string) {
                            string = string.replace(/\r\n/g, "\n");
                            var utftext = "";

                            for (var n = 0; n < string.length; n++) {
                                var c = string.charCodeAt(n);

                                if (c < 128) {
                                    utftext += String.fromCharCode(c);
                                } else if (c > 127 && c < 2048) {
                                    utftext += String.fromCharCode((c >> 6) | 192);
                                    utftext += String.fromCharCode((c & 63) | 128);
                                } else {
                                    utftext += String.fromCharCode((c >> 12) | 224);
                                    utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                                    utftext += String.fromCharCode((c & 63) | 128);
                                }
                            }

                            return utftext;
                        },

                        /* private method for UTF-8 decoding */
                        _utf8_decode: function (utftext) {
                            var string = "";
                            var i = 0;
                            var c3 = 0;
                            var c2 = 0;
                            var c = 0;

                            while (i < utftext.length) {
                                c = utftext.charCodeAt(i);

                                if (c < 128) {
                                    string += String.fromCharCode(c);
                                    i++;
                                } else if (c > 191 && c < 224) {
                                    c2 = utftext.charCodeAt(i + 1);
                                    string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
                                    i += 2;
                                } else {
                                    c2 = utftext.charCodeAt(i + 1);
                                    c3 = utftext.charCodeAt(i + 2);
                                    string += String.fromCharCode(
                                        ((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63)
                                    );
                                    i += 3;
                                }
                            }
                            return string;
                        }
                    };
                    window.Base64 = Base64;
                    return Base64.encode(str);
                },
            }),
            mouseov(ob) {
                this.ods = ob
            },
            mousout() {
                this.ods = ''
            },
            showToast(msg) {
                this.$bvToast.toast(msg, {
                    title: ``,
                    toaster: 'b-toaster-bottom-center',
                    solid: true,
                    variant: 'danger',
                    appendToast: false
                })
            },
            logout() {
                localStorage.removeItem("token");
        localStorage.removeItem("store");
        localStorage.removeItem("usrname");
        localStorage.removeItem("usrid");
        this.$store.state.usr = "";
        this.$store.state.usrid = "";
        this.$store.state.access_token = "";
        this.$store.state.items_cart = [];

            },
        }
    }
</script>