<template>
  <div>
    <Head-section></Head-section>
    <div class="container">
      <b-media class="mb-1">
        <b-img slot="aside" v-bind="mainProps" :src="require('../assets/user.jpg')" rounded="circle" alt="User head">
        </b-img>
        <h5 class="mt-1 font-weight-bold" v-if="access_token!=''">{{loginname}},{{$t("message.Welcome_you")}}</h5>
        <p class="text-muted">{{email}}</p>
        <p v-if="is_mem" class="text-success">{{$t("message.Recommendation_code")}}:{{promotioncode}}</p>
        <p v-if="is_mem" class="text-success">{{$t("message.Coupon_now")}}:{{parseFloat((1-rate)*100 ).toFixed(0)}}%
          {{$t("message.Off")}}</p>
      </b-media>

      <b-button href="#" class="my-3" variant="outline-success" @click="toShare()">
        <i class="fas fa-coins"></i>
        {{ $t("message.Brokerage") }}
      </b-button>

      <b-button href="#" class="my-3 mx-3" variant="outline-danger" @click="logout()">{{ $t("message.Logout") }}
      </b-button>

      <div class="card mb-3">
        <div class="card-header text-center">-----------{{$t("message.Transaction_Record")}}-----------</div>
        <div class="card-body p-3">
          <span class="card-text" @click="item_type=0">{{$t("message.My_Order")}}</span>
        </div>

        <div class="card-header text-center">-----------{{$t("message.Personal_Center")}}-----------</div>
        <div class="card-body p-3">
          <p class="card-text" @click="item_type=2" v-if="lan=='cn'">{{$t("message.Tran_Addr")}}</p>
          <hr v-if="lan=='cn'">
          <p class="card-text" @click="item_type=1">{{$t("message.Change_Password")}}</p>
          <hr>
          <p class="card-text" @click="item_type=3" v-if="access_token!=''">{{$t("message.Commission_account")}}</p>
        <hr>
         <b-link class="card-text text-dark" :href="'/'+lan+'/question'" v-if="access_token!=''">
            {{$t('message.Contact')}}</b-link>
        </div>
      </div>
      <OrderLs-Section v-if="item_type==0"></OrderLs-Section>
      <ModifyPsw-Section v-if="item_type==1"></ModifyPsw-Section>
      <Addr-Section v-if="item_type==2"></Addr-Section>
      <CommsionLs-Section v-if="item_type==3"></CommsionLs-Section>
    </div>

    <Foot-section></Foot-section>
  </div>
</template>

<script>
  import {
    mapGetters,
    mapActions
  } from "vuex";

  import HeadSection from "./HeadSection.vue";
  import FootSection from "./FootSection.vue";
  import OrderLsSection from "./OrderLsSection.vue";
  import ModifyPswSection from "./ModifyPswSection.vue";
  import AddrSection from "./AddrSection.vue";
  import CommsionLsSection from "./CommsionLsSection.vue";
  export default {
    name: "Ucenter",
    components: {
      HeadSection,
      FootSection,
      OrderLsSection,
      ModifyPswSection,
      AddrSection,
      CommsionLsSection
    },
    computed: {
      ...mapGetters(["loginname", "email", "lan", "access_token"])
    },
    data() {
      return {
        item_type: 0,
        is_mem: false,
        mainProps: {
          width: 75,
          height: 75,
          class: "m1"
        },
        promotioncode: 0,
        rate: 1
      };
    },
    created() {
      if (this.access_token == "") this.toHome();
      else this.isMem();
    },
    watch: {
      lan(newlan, oldlan) {
        if (this.access_token == "") this.toHome();
        else this.isMem();
      }
    },
    methods: {
      ...mapActions({
        isMem(dispatch) {
          dispatch("isMem", {}).then(data => {
            this.is_mem = data.result;
            if (data.result) {
              this.promotioncode = data.promotioncode;
              this.promotionRate(data.promotioncode);
            }
          });
        },

        promotionRate(dispatch, promotioncode) {
          dispatch("promotionRate", {
            promotioncode
          }).then(data => {
            this.rate = data.item.rate;
          });
        }
      }),
      toShare() {
        if (!this.is_mem) {
          this.showToast(this.$t("message.Not_member"))
          return;
        }

        this.$router.push({
          name: "Share"
        });
      },
      showToast(msg) {
        this.$bvToast.toast(msg, {
          title: ``,
          toaster: "b-toaster-bottom-center",
          solid: true,
          variant: "danger",
          appendToast: false
        });
      },
      logout() {
        localStorage.removeItem("token");
        localStorage.removeItem("store");
        localStorage.removeItem("usrname");
        localStorage.removeItem("usrid");
        this.$store.state.usr = "";
        this.$store.state.usrid = "";
        this.$store.state.access_token = "";
        this.$store.state.items_cart = [];
        this.toHome();
      },
      toHome() {
        if (this.access_token == "")
          this.$router.push({
            name: "enhome"
          });
      }
    }
  };
</script>