<template>
  <div>
    <div>
      <sapn class="float-left bg-dark p-1 text-white text-justify">{{$t('message.Commission_account')}}</sapn>
      <span
        class="float-right font-weight-bold text-justify"
      >{{$t('message.Current_commission')}}:{{symble_money}}{{current_comm}}</span>
    </div>
    <div class="table-responsive">
      <table class="table table-bordered ">
        <thead class="table-secondary">
          <tr>
            <th scope="col">{{$t("message.Order_Number")}}</th>
            <th scope="col">{{$t("message.Order_Date")}}</th>
            <th scope="col">{{$t("message.Buyer")}}</th>
            <th scope="col">{{$t("message.Order_Status")}}</th>
            <th scope="col">{{$t("message.Brokerage")}}</th>
            <th
              scope="col"
              v-b-popover.hover="$t('message.Withdraw_time')"
            >
              {{$t("message.Withdraw")}}
              <span class="badge badge-light">
                <i class="fas fa-hand-point-up"></i>
              </span>
              
            </th>
          </tr>
        </thead>
        <tbody v-for="od in items_content" :key="od">
          <tr>
            <td>{{od.orderno}}</td>
            <td>{{od.buytime | time}}</td>
            <td>{{od.buyer}}</td>
            <td v-if="od.state==0">{{$t("message.Pre_acquisition")}}</td>
            <td v-if="od.state==1">{{$t("message.Can_withdraw")}}</td>
            <td v-if="od.state==2">{{$t("message.Invalid")}}</td>
            <td>{{symble_money}}{{od.commission}}</td>
            <td ><b-button pill variant="success">{{$t("message.Withdraw")}}</b-button></td>
          </tr>
        </tbody>
      </table>
    </div>
    <nav aria-label="Page navigation example ">
      <ul class="pagination justify-content-center mt-3">
        <li class="page-item">
          <a class="page-link" href="#" @click="prePage()">Previous</a>
        </li>
        <!-- <li class="page-item" v-forv-for="(tt,idx) in total_pg" :key="tt" :class="{active:idx==pg}">
                <a class="page-link" href="#" @click="getForumLs(foruid,tt-1)">{{tt}}</a>
        </li>-->
        <li class="page-item" :class="{active:(page1-1)==pg}">
          <a class="page-link" href="#" @click="getCommisionLs(page1-1)">{{page1}}</a>
        </li>
        <li class="page-item" :class="{active:(page2-1)==pg}" v-if="total>1">
          <a class="page-link" href="#" @click="getCommisionLs(page2-1)">{{page2}}</a>
        </li>
        <li class="page-item" :class="{active:(page3-1)==pg}" v-if="total>2">
          <a class="page-link" href="#" @click="getCommisionLs(page3-1)">{{page3}}</a>
        </li>
        <li class="page-item" :class="{active:(page4-1)==pg}" v-if="total>3">
          <a class="page-link" href="#" @click="getCommisionLs(page4-1)">{{page4}}</a>
        </li>
        <li class="page-item" :class="{active:(page5-1)==pg}" v-if="total>4">
          <a class="page-link" href="#" @click="getCommisionLs(page5-1)">{{page5}}</a>
        </li>
        <li class="page-item">
          <a class="page-link" href="#" @click="nextPage()">Next</a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "CommsionLsSection",
  data() {
    return {
      total: 0,
      total_pg: 5,
      current_comm: 0,
      pg: 0,
      page1: 1,
      page2: 2,
      page3: 3,
      page4: 4,
      page5: 5,
      items_content: []
    };
  },
  computed: {
    ...mapGetters(["symble_money", "access_token", "lan"])
  },

  created() {
    this.getCommisionLs(0);
    this.getCommision();
  },
  methods: {
    ...mapActions({
      getCommision(dispatch) {
        dispatch("getCommision", {}).then(data => {
          if (data.result) {
            this.current_comm = data.commission;
          }
        });
      },
      getCommisionLs(dispatch, pg) {
        let pgs = 20;
        this.pg = pg;

        dispatch("getCommisionLs", { pg, pgs }).then(data => {
          if (data.result) {
            this.total = Math.ceil(data.total / 20);
            if (this.total <= 5) {
              this.total_pg = this.total;
            }
            this.items_content = data.items;
          }
        });
      }
    }),
    nextPage() {
      if (this.pg + 1 >= this.total) return;
      if (this.pg < this.page5 - 1) {
        this.getCommisionLs(this.pg + 1);
      } else {
        this.page1 = this.page1 + 5;
        this.page2 = this.page2 + 5;
        this.page3 = this.page3 + 5;
        this.page4 = this.page4 + 5;
        this.page5 = this.page5 + 5;
        this.getCommisionLs(this.pg + 1);
      }
    },
    prePage() {
       if(this.pg<=0)
            return
      if (this.pg < this.page1 - 1) {
        this.getCommisionLs(this.pg - 1);
      } else {
        this.page1 = this.page1 - 5;
        this.page2 = this.page2 - 5;
        this.page3 = this.page3 - 5;
        this.page4 = this.page4 - 5;
        this.page5 = this.page5 - 5;
        this.getCommisionLs(this.pg - 1);
      }
    }
  }
};
</script>

